import { Route, Routes } from 'react-router-dom';
import './App.css';
import './style.scss'
import HomeWrapper from './pages/HomeWrapper';
import HomePage from './components/HomePage/HomePage';
import DashboardPage from './components/DashboardPage/DashboardPage';
import ProductPage from './components/ProductPage/ProductPage';
import LoginPage from './components/LoginPage/LoginPage';
import RegistrationPage from './components/RegistrationPage/RegistrationPage';
import Confirmation from './components/Confirmation/Confirmation';
import CanclePayment from './components/CanclePayment/CanclePayment';
import MessagesWrapper from './pages/MessagesWrapper';
import ErrorPage from './pages/ErrorPage';
import TermsPage from './components/TermsPage/TermsPage';
import CookiesPage from './components/CookiesPgae/CookiesPage';
import LegalPage from './components/LegalPage/LegalPage';
import Tariff from './components/Tariff/Tariff';
import AdminLogin from './components/AdminLogin/AdminLogin';
import Admin from './components/Admin/Admin';
import ForgotPassPage from './components/ForgotPassPage/ForgotPassPage';
import { ToastContainer } from 'react-toastify';
import MyProductsPage from './components/MyProductsPage/MyProductsPage';
import NoDataPage from './components/NoDataPage/NoDataPage';

function App() {
  return (
    <>
    <ToastContainer />
     <Routes>
        <Route path='/' element={<HomeWrapper />}>
          <Route index element={<HomePage />} />
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='my-products' element={<MyProductsPage />} />
          <Route path='tables' element={<NoDataPage title='Tables' />} />
          <Route path='billing' element={<NoDataPage title='Billing' />} />
          <Route path='my-products' element={<MyProductsPage />} />
          <Route path='product' element={<ProductPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='forgot-pass' element={<ForgotPassPage />} />
          <Route path='admin-login' element={<AdminLogin />} />
          <Route path='registration' element={<RegistrationPage />} />
          <Route path="legal" element={<LegalPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="cookies" element={<CookiesPage />} />
          <Route path='tarifs' element={<Tariff />} />
          <Route path='admin' element={<Admin />} />
        </Route>
        <Route path={`/messagges`} element={<MessagesWrapper />}>
          <Route path="confirmation" element={<Confirmation />} />
          <Route path="cancel-payment" element={<CanclePayment />} />
        </Route>
        <Route path='*' element={<ErrorPage />}></Route>
     </Routes>
    </>
  );
}

export default App;
