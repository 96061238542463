import React from 'react';
import { Image } from '../../Images/Image';
import { Link, useLocation } from 'react-router-dom';

const Help = () => {
  const location = useLocation();

  return (
    <div className="help">
      <div className="help-location">
        <Link
          to="/dashboard"
          className={location.pathname === '/dashboard' ? 'help-active' : ''}
        >
          <img src={Image.helpDefault} alt="img" />
          <span>Dashboard</span>
        </Link>
        <Link
          to="/tables"
          className={location.pathname === '/tables' ? 'help-active' : ''}
        >
          <img src={Image.helpChart} alt="img" />
          <span>Tables</span>
        </Link>
        <Link
          to="/billing"
          className={location.pathname === '/billing' ? 'help-active' : ''}
        >
          <img src={Image.helpDefault1} alt="img" />
          Billing
        </Link>
        <Link
          to="/my-products"
          className={location.pathname === '/my-products' ? 'help-active' : ''}
        >
          <img src={Image.helpDefault2} alt="img" />
          My products
        </Link>
      </div>
      <div className="help-payment">
        <div className="help-payment_item">
          <div className="help-title">
            <h3>Today’s Money</h3>
            <p>$0</p>
          </div>
          <img src={Image.helpDefault3} alt="img" />
        </div>
        <div className="help-payment_item">
          <div className="help-title">
            <h3>Today’s Users</h3>
            <p>0</p>
          </div>
          <img src={Image.helpOut} alt="img" />
        </div>
        <div className="help-payment_item">
          <div className="help-title">
            <h3>Total Sales</h3>
            <p>$0</p>
          </div>
          <img src={Image.helpDefault4} alt="img" />
        </div>
      </div>
      <div className="help-info">
        <img src={Image.helpCircle} alt="img" />
        <h4>Need help?</h4>
        <p>Please check our docs</p>
        <button>DOCUMENTATION</button>
      </div>
    </div>
  );
};

export default Help;