import React from 'react'
import Dashboard from '../Dashboard/Dashboard'
import StaticsOverivew from '../StaticsOverivew/StaticsOverivew'
import MyProducts from '../MyProducts/MyProducts'

function MyProductsPage() {
  return (
    <>
      <MyProducts />
    </>
  )
}

export default MyProductsPage
