import { useRef, useState, FormEvent } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Image } from '../../Images/Image';
import PositonButton from '../../uikit/PositonButton/PositonButton';

function ForgotPass() {
  const [step, setStep] = useState<number>(1); // Управление этапами
  const [userId, setUserId] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const handleFirstStep = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formRef.current) return;

    const formData = new FormData(formRef.current);
    const email = formData.get('email') as string | null;

    try {
      const response = await axios.post('https://bazaar-deals.vercel.app/api/auth/forgot-password', { email });
      setUserId(response.data.userId); // Сохраняем userId для следующего шага
      toast.success('Verification code sent to your email.');
      setStep(2); // Переход на следующий этап
      formRef.current.reset(); // Сброс формы
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to send verification code.';
      toast.error(errorMessage);
    }
  };

  const handleSecondStep = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formRef.current) return;

    const formData = new FormData(formRef.current);
    const code = formData.get('code') as string | null;

    try {
      await axios.post('https://bazaar-deals.vercel.app/api/auth/verify-reset-code', { userId, code });
      toast.success('Code verified successfully.');
      setStep(3); // Переход на следующий этап
      formRef.current.reset(); // Сброс формы
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Invalid verification code.';
      toast.error(errorMessage);
    }
  };

  const handleFinalStep = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formRef.current) return;

    const formData = new FormData(formRef.current);
    const newPassword = formData.get('newPassword') as string | null;
    const confirmPassword = formData.get('confirmPassword') as string | null;

    try {
      await axios.post('https://bazaar-deals.vercel.app/api/auth/recover-password', { newPassword, confirmPassword });
      toast.success('Password reset successfully.');
      setStep(1); // Возвращаемся к первому этапу
      formRef.current.reset(); // Сброс формы
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to reset password.';
      toast.error(errorMessage);
    }
  };

  return (
    <section className="login">
      <div className="login__container _container">
        <img className="login-left" src={Image.loginEllipse} alt="Decorative Ellipse" />
        <PositonButton title="Password Recovery" image={Image.loginAlarm} />
        <img className="login-right" src={Image.loginAbstract} alt="Abstract Decorative" />
        <h1>Password Recovery</h1>
        <form ref={formRef} onSubmit={step === 1 ? handleFirstStep : step === 2 ? handleSecondStep : handleFinalStep}>
          {step === 1 && (
            <>
              <label htmlFor="email">
                Email Address
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Enter your email"
                  required
                />
              </label>
              <label className="input-btn">
                <input type="submit" value="Send Code" />
              </label>
            </>
          )}
          {step === 2 && (
            <>
              <label htmlFor="code">
                Verification Code
                <input
                  id="code"
                  name="code"
                  type="text"
                  placeholder="Enter verification code"
                  required
                />
              </label>
              <label className="input-btn">
                <input type="submit" value="Verify Code" />
              </label>
            </>
          )}
          {step === 3 && (
            <>
              <label htmlFor="newPassword">
                New Password
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Enter new password"
                  required
                />
              </label>
              <label htmlFor="confirmPassword">
                Confirm Password
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm new password"
                  required
                />
              </label>
              <label className="input-btn">
                <input type="submit" value="Reset Password" />
              </label>
            </>
          )}
        </form>
      </div>
    </section>
  );
}

export default ForgotPass;