import React, { ChangeEvent, FormEvent, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Image } from "../../Images/Image";

function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    budget: "",
    message: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await axios.post("https://bazaar-deals.vercel.app/api/email/contact-us", {
        email: formData.email,
      });
      toast.success("Message sent successfully!");
      setFormData({
        fullName: "",
        email: "",
        phoneNumber: "",
        budget: "",
        message: "",
      });
    } catch (error: any) {

      const errorMessage = error?.response?.data?.message || "Failed to send message. Please try again.";
      toast.error(errorMessage);
    }
  };

  return (
    <section className="contact" id="contact">
      <div className="contact__container _container">
        <div className="contact-title">
          <h2>Contact us!</h2>
          <p>
            We are here to help you. If you have questions, comments or simply want to know more about our services, don't hesitate to
            contact us. We are available to listen and give you the support you need to achieve your business goals.
          </p>
          <div className="contact-info">
            <button>
              <img src={Image.contactImg} alt="img" />
            </button>
            <ul>
              <li>Call us</li>
              <a href="/">+8801613968687</a>
            </ul>
          </div>
          <div className="contact-info">
            <button>
              <img src={Image.contactImg2} alt="img" />
            </button>
            <ul>
              <li>Email me</li>
              <a href="/">ahmedtanvir8687@gmail.com</a>
            </ul>
          </div>
          <div className="contact-info">
            <button>
              <img src={Image.contactImg3} alt="img" />
            </button>
            <ul>
              <li>Visit us </li>
              <a href="/">Zakigonj, Sylhet, Bangladesh.</a>
            </ul>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="contact__form">
          <div className="contact__form-grid">
            <label className="input-text">
              <input
                type="text"
                name="fullName"
                placeholder="Full name"
                value={formData.fullName}
                onChange={handleChange}
              />
            </label>
            <label className="input-text">
              <input
                type="text"
                name="email"
                placeholder="Your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label className="input-text">
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </label>
            <label className="input-text">
              <input
                type="text"
                name="budget"
                placeholder="Budget"
                value={formData.budget}
                onChange={handleChange}
              />
            </label>
          </div>
          <label className="input-text">
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            />
          </label>
          <label className="input-btn">
            <input type="submit" value="Send a message" />
          </label>
        </form>
        {/* Toast container for notifications */}
        <ToastContainer position="top-right" autoClose={3000} />
      </div>
    </section>
  );
}

export default Contact;