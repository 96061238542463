import React from 'react'
import Login from '../Login/Login'
import Subscribe from '../Subscribe/Subscribe'
import ForgotPass from '../ForgotPass/ForgotPass'

function ForgotPassPage() {
  return (
    <>
      <ForgotPass />
    </>
  )
}

export default ForgotPassPage
