import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Image } from "../../Images/Image";

function Subscribe() {
  const [email, setEmail] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await axios.post("https://bazaar-deals.vercel.app/api/subscribers", {
        email,
      });
      toast.success("Subscribed successfully!");
      setEmail(""); // Сброс формы
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || "Failed to subscribe. Please try again.";
      toast.error(errorMessage);
    }
  };

  return (
    <section
      className="subscribe"
      style={{ backgroundImage: `url(${Image.subscribeBack})` }}
    >
      <div className="subscribe__container _container">
        <p>NEWSLETTER</p>
        <h2>Subscribe for offers and updates!</h2>
        <form className="subscribe-input" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Your email and you're ready"
            value={email}
            onChange={handleChange}
          />
          <button type="submit">Subscribe</button>
          <img className="polygon-img" src={Image.subscribePolygon} alt="img" />
          <img src={Image.subscribeEllipse} alt="img" />
        </form>
      </div>
    </section>
  );
}

export default Subscribe;