import { useNavigate } from 'react-router-dom';
import { Image } from '../../Images/Image';
import Help from '../Help/Help';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { usersSelector } from '../../store/slices/users/usersSlice';



function NoData({title}: {title: string}) {
    const { userToken } = useSelector(usersSelector);
    const navigate = useNavigate()

    useEffect(() => {
        if (!userToken) {
            navigate('/')
        }
    }, [userToken]);

    return (
        <section className="dashboard">
            <div className="dashboard__container _container">
                <Help />
                <div className="dashboard-block">
                    <h1>{title}</h1>
                    <p>At the moment, there are no active sales, and the information is currently unavailable</p>
                </div>
            </div>
        </section>
    );
}

export default NoData;