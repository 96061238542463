import Header from '../components/Header/Header'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/Footer/Footer'
import Subscribe from '../components/Subscribe/Subscribe'

function HomeWrapper() {
    const location = useLocation()
    return (
        <div className='wrapper'>
            <Header />
            <main className='page'>
                <Outlet />
                {location.pathname !== '/admin-login' && location.pathname !== '/admin' && <Subscribe />}
            </main>
            {location.pathname !== '/admin-login' && location.pathname !== '/admin' && <Footer />}
        </div>
    )
}

export default HomeWrapper
