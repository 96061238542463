import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usersSelector } from '../../store/slices/users/usersSlice'
import { useNavigate } from 'react-router-dom'

const Admin = () => {
    const [usersData, setUsersData] = useState<any[]>([])
    const [getUsersData, setGetUsersData] = useState<boolean>(true)
    const { adminUserToken } = useSelector(usersSelector)
    const [page, setPage] = useState<number>(1)
    const navigate = useNavigate()

    useEffect(() => {
        if (adminUserToken) {
            if (getUsersData) {
                axios.get(`https://bazaaar.deals/api/user?page=${page}`, {
                    headers: {
                        Authorization: `Bearer ${adminUserToken}`
                    }
                }).then(res => {
                    setUsersData(res.data.users?.map((el: any) => ({
                        id: el._id,
                        name: el.firstName,
                        lastName: el.lastName,
                        email: el.email,
                        password: el.password,
                        togglePass: false
                    })));
                    setGetUsersData(false);
                }).catch(error => {
                    setGetUsersData(false);
                });
            }
        } else {
            navigate('/admin-login')
        }
    }, [getUsersData]);


    return (
        <section className='admin'>
            <div className="admin__container _container">
                <div className="admin__title">
                    <h1>Users</h1>
                    <div className="buttons">
                        <button onClick={() => { setPage((page >= 2 ? (page - 1) : page)); setGetUsersData(true) }}>
                            <svg width={61} height={61} viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30.5" cy="30.5" r={30} fill="white" stroke="#EDEEEF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M28.2426 35.8038L23.4697 31.0308C23.1768 30.7379 23.1768 30.263 23.4697 29.9701L28.2426 25.1972C28.5355 24.9043 29.0104 24.9043 29.3033 25.1972C29.5962 25.4901 29.5962 25.9649 29.3033 26.2578L25.8107 29.7505H37C37.4142 29.7505 37.75 30.0863 37.75 30.5005C37.75 30.9147 37.4142 31.2505 37 31.2505H25.8107L29.3033 34.7431C29.5962 35.036 29.5962 35.5109 29.3033 35.8038C29.0104 36.0967 28.5355 36.0967 28.2426 35.8038Z" fill="#152137" />
                            </svg>
                        </button>
                        <button onClick={() => { setPage((page + 2)); setGetUsersData(true) }}>
                            <svg width={61} height={61} viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30.5" cy="30.5" r={30} fill="white" stroke="#EDEEEF" />
                                <path d="M35.5429 29.3969L36.3964 30.2505H35.1893H24C23.8619 30.2505 23.75 30.3624 23.75 30.5005C23.75 30.6385 23.8619 30.7505 24 30.7505H35.1893H36.3964L35.5429 31.604L32.0503 35.0967C31.9526 35.1943 31.9526 35.3526 32.0503 35.4502C32.1479 35.5478 32.3062 35.5478 32.4038 35.4502L37.1768 30.6772C37.2744 30.5796 37.2744 30.4213 37.1768 30.3237L32.4038 25.5507C32.3062 25.4531 32.1479 25.4531 32.0503 25.5507C31.9526 25.6483 31.9526 25.8066 32.0503 25.9043L35.5429 29.3969Z" fill="white" stroke="#152137" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="contracts-table">
                    <table>
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>Name</td>
                                <td>Last name</td>
                                <td>Email</td>
                                {/* <td>Password</td> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usersData.length ? (
                                    usersData.map(el => (
                                        <tr key={el.id}>
                                            <td>{el.id}</td>
                                            <td>{el.name}</td>
                                            <td>{el.lastName}</td>
                                            <td>{el.email}</td>
                                            {/* <td>{
                                                el.togglePass ? el.password : <button>***</button>
                                            }</td> */}
                                        </tr>
                                    ))
                                ) : ''
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default Admin
