import NoData from "../NoData/NoData"


function NoDataPage({title}: {title: string}) {
  return (
    <>
      <NoData title={title} />
    </>
  )
}

export default NoDataPage
